import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { Angulartics2Module } from 'angulartics2';
// import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectDetailComponent } from './components/projects/project-detail/project-detail.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

const appRoutes: Routes = [
    { path: '', redirectTo: '/projects', pathMatch: 'full' },
    {
        path: 'projects',
        component: ProjectsComponent,
        data: { state: 'projects', num: 1 }
    },
    {
        path: 'about',
        component: AboutComponent,
        data: { state: 'about', num: 2 }
    },
    {
        path: 'contact',
        component: ContactComponent,
        data: { state: 'contact', num: 3 }
    },
    {
        path: "projects/project-detail/:id",
        component: ProjectDetailComponent,
        data: { state: 'project-info', num: 0 }
        // loadChildren: "projects/project-detail/"
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {
                enableTracing: true, // <-- debugging purposes only
                useHash: true
            }
        ),
        //  Angulartics2Module.forRoot([Angulartics2GoogleAnalytics])
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }