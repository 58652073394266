import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';

@Component({
  selector: 'image-grid',
  templateUrl: './image-grid.component.html',
  styleUrls: ['./image-grid.component.css']
})
export class ImageGridComponent implements AfterViewInit {
  public togglePopup: boolean = false;
  public selectedImage:String = '';
  public caption:string = "";
  public figureCaptions = [
    {imgSrc:'',imgCap:'2014 LA Kings win their second cup.'},
    {imgSrc:'',imgCap:'Christmas photos at the Hermosa Beach pier.'},
    {imgSrc:'',imgCap:'Fishing in Bishop CA.'},
    {imgSrc:'',imgCap:'Hanging at the beach with the kids during Hermosa Beach Concerts.'},
    {imgSrc:'',imgCap:'Hiking and fishing, South Lake Bishop Creek.'},
    {imgSrc:'',imgCap:'Teaching my son how to skate.'},
  ]

  constructor() { }

  @ViewChild('imagepopup', {read: ElementRef}) imagePopupView: ElementRef;

  ngAfterViewInit(): void {
 
  }

  openImage(evt) {
    // Send image to popover comp
    console.log("Open selected image "+evt.target.parentElement.children);
    console.log(evt);
    console.log(evt.target.parentElement.children);
    const newImageSrc:String = evt.target.src;
    this.selectedImage = newImageSrc;

   this.togglePopup = true;
    console.log(newImageSrc);

    this.updateCaption(evt.target.id);
  }
  updateCaption(id: string) {
    let _id = Number(id)-1;
    this.caption = this.figureCaptions[_id].imgCap;
  }
  closePopup(evt?) {
    console.log("Close the popup window");
  //  this.imagePopupView.nativeElement.hidden = true;
    this.togglePopup = false;
  }
}
