import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule }    from '@angular/forms';

import { ProjectsComponent }    from './projects.component';
import { ProjectDetailComponent }  from './project-detail/project-detail.component';
//import { ProjectService } from "../../services/projects.service";

// import { ProjectRoutingModule } from './project-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // ProjectRoutingModule
  ],
  declarations: [
    ProjectsComponent,
    ProjectDetailComponent
  ],
  providers: []
})
export class ProjectsModule {}
