import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
//import { AppComponent } from '../../app.component';

@Component({
  selector: 'mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.css']
})
export class MobileMenuComponent implements OnInit {
  public data;
  public closeBtn;

@ViewChild('menuclose') menuclose: ElementRef;
@ViewChild('removemenu') removeMenu: ElementRef;

  @Input()
  @Output() closeClick: EventEmitter<String> = new EventEmitter<String>();

  constructor() {
    console.log("mo-men invoked")
  }

  ngOnInit() {
   // console.log("Mobile Menu INIT "+this.moMenu);
   //this.closeBtn = document.getElementsByClassName("closeBtn");

  }
  closeMenu(event) {
    //console.log(event);
    this.closeClick.emit(event);
  }
}
