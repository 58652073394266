import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';

import { MobileMenuModule } from '../mobile-menu/mobile-menu.module';
import { MobileMenuComponent } from '../mobile-menu/mobile-menu.component';
import { MenuComponent } from './menu.component';

// import { ProjectRoutingModule } from './project-routing.module';

@NgModule({
  imports: [
    CommonModule,
   // MobileMenuComponent
   // MobileMenuModule
    // ProjectRoutingModule
  ],
  //entryComponents: [MobileMenuComponent],
  declarations: [
   // MenuComponent
  ],
  providers: []
})
export class MenuModule {}