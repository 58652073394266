import { Component, Output, EventEmitter, AfterViewInit, OnChanges, ComponentFactoryResolver, ViewContainerRef, ViewChild, Renderer, OnDestroy, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { Router, NavigationEnd } from "@angular/router";
import { routerTransition } from './app-router.animations';
import { NavService } from './services/navigation.service';
// import { MenuComponent } from './components/menu/menu.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
   animations: [routerTransition]

})
export class AppComponent implements AfterViewInit, OnInit {
  // static componentFactory = null;
  // static containerRef = null;
  static deviceIsTouch = null;
  static mobileMenuDarkenStyles;
  static darkenBg = false;
  static currMmState;

  private isNavigating = false;

  constructor(
    private navigationService: NavService
  ) { }

  @Output() myEvt: EventEmitter<String> = new EventEmitter<String>();

  ngOnInit() {

    // }
    // ngAfterViewInit() {
    AppComponent.deviceIsTouch = ('ontouchstart' in document.documentElement);

    // this.darkenBg = { 'background-darken': AppComponent.mobileMenuDarkenStyles };
    //this.darkenBg = AppComponent.mobileMenuDarkenStyles;

  }
  ngAfterViewInit() {
  }

  public static updateMoMenu(boo) {
    AppComponent.mobileMenuDarkenStyles = boo;
  }
  public static addMobileMenu(state?: boolean) {
    AppComponent.currMmState = state;
    return AppComponent.darkenBg = state;
  }
  public removeMobileMenu(evt) {
    console.log("Closing Menu from AppComponent");
    this.myEvt.next(evt);
    //this.menuComp.closeMobileMenu(evt);
  }
  get staticStyle() {
    return AppComponent.addMobileMenu(AppComponent.currMmState)
  }
  public getNavState(outlet) {
    this.isNavigating = true;
    // console.log("Get nav State")
    // console.log(outlet instanceof NavigationEnd);
    //TODO: Why is this thing getting called when I rollover the projects image
    //  console.log('lastPage', this.navigationService.lastValue);
    //  console.log("currPage "+this.navigationService.newValue);
    //  console.log("Animate "+this.navigationService.animationValue);
    return this.navigationService.animationValue;
   // return outlet.activatedRouteData.state;
  }
}
