import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, Validator } from '@angular/forms';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { environment } from '../environments/environment';

import { Router } from '@angular/router';
import { ProjectService } from "./services/projects.service";


/* NG Material2 */
// import { 
//   MatButtonModule, 
//   MatCardModule, 
//   MatToolbarModule, 
//   MatMenuModule,
//   MatIconModule
// } from '@angular/material';

import { Angular2FontawesomeModule } from 'angular2-fontawesome/angular2-fontawesome'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ProjectsModule } from './components/projects/projects.module';
import { MobileMenuModule } from './components/mobile-menu/mobile-menu.module';

import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectDetailComponent } from './components/projects/project-detail/project-detail.component';
import { MenuComponent } from './components/menu/menu.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { MenuModule } from './components/menu/menu.module';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import { FooterComponent } from './components/footer/footer.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ImageGridComponent } from './components/image-grid/image-grid.component';
import { PopoverImageComponent } from './components/popover-image/popover-image.component';
import { SocialComponent } from './components/social/social.component';
import { NavService } from './services/navigation.service';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    AboutComponent,
    ContactComponent,
    MobileMenuComponent,
    PageNotFoundComponent,
    FooterComponent,
    ContactFormComponent,
    ImageGridComponent,
    PopoverImageComponent,
    SocialComponent
  ],
  entryComponents: [MobileMenuComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ProjectsModule,
    MenuModule,
    HttpClientModule,
    Angular2FontawesomeModule,
    NgbModule.forRoot(),
    AngularFireDatabaseModule, // imports firebase/firestore, only needed for database features
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule
  ],
  providers: [
    ProjectService,
    NavService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  // Diagnostic only: inspect router configuration
  constructor(router: Router) {
    console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
  }
}
