import { Component, OnInit } from '@angular/core';
import { animate, state, style, trigger } from '@angular/animations';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { TweenLite, Power1, Power3 } from "gsap";
import { Project } from "./project.model";
import { ProjectService } from "../../services/projects.service";

import { Router, NavigationExtras } from '@angular/router';
import { AppComponent } from '../../app.component';
import { Observable } from 'rxjs';
import { transition } from '@angular/animations';
import { Config } from 'protractor';


@Component({
	selector: 'projects',
	templateUrl: 'projects.component.html',
	styleUrls: ['projects.component.css'],
	//	animations: [fadeAnimation]
})

export class ProjectsComponent implements OnInit {
	public imageDir: string = "./assets/proj-images/";
	public projects = new Array();
	private _screenSize = window.innerWidth;
	private _isTouchDevice = AppComponent.deviceIsTouch;
	private preloadedImages = new Object();
	public imgURL;
	public imgPreloadUrl = null;

	constructor(
		private projectService: ProjectService,
		private route: Router,
		private sanitization: DomSanitizer
	) {
		this.route.events.subscribe(() => {
			window.scrollTo(0, 0);
		})
	};

	ngOnInit() {
		console.log("Init Projects")

		this.getProjResponse();

		//	console.log(this.projects);

		this.onTouchDevice();
	}
	public getProjResponse() {
		//	console.log("All Projects:")
		this.projectService.getAllProjects()
			.subscribe(data => {
				data['projects'].forEach(element => {
					//console.log(element.name);
					this.projects.push(element);
					for (let i = 0; i < element["images"].length; i++) {
						//	this.beginLoadingImage(this.imageDir+element["images"][i])
					}
				});
			})
	}
	public beginLoadingImage(img): string {
		let newImage = new Image();
		console.log(newImage);
		//	newImage.src = img;
		//	newImage.srcset = img;
		newImage.addEventListener('load', imgEle => {
			console.log("Image Load Complete ");
			console.log(imgEle);
			//	return this.imageDir+imgEle.srcElement;
			return `url(${this.imageDir + imgEle.srcElement})`;
			//	imgEle.currentTarget.removeEventListener('load');
			//newImage.src = newImage.src;
		})
		return "";
		// console.log(newImage.src);
		// return newImage.src;
	}
	private onTouchDevice(): boolean {
		console.log("on Touch Device? " + this._isTouchDevice);
		return this._isTouchDevice;
	}
	private selectProject(proj) {
		console.log("Project Clicked: " + proj.id)
		let navigationExtras: NavigationExtras = {
			queryParams: { 'id': proj.id }
		};
		this.route.navigate(['projects/project-detail', proj.id], navigationExtras);
	}
	private overProject(e) {
		const projImage = e.target.children[0].children[0];
		const projInfo = e.target.children[1];
		console.log(e);
		if (!this._isTouchDevice) {
			let fadeBgIn = TweenLite.to(projImage, .5, {
				opacity: .4,
				ease: Power3.easeOut
			})
			let scaleUpBgImg = TweenLite.to(projImage, 3, {
				scaleX: 1.20,
				scaleY: 1.20,
				ease: Power3.easeOut
			})
			let fadeInfoIn = TweenLite.to(projInfo, .5, {
				opacity: .9
			})
			projImage.style.cursor = "pointer";
		}
	}
	public outProject(e) {
		if (!this._isTouchDevice) {
			const projImage = e.target.children[0].children[0];
			const projInfo = e.target.children[1];
			let fadeBgOut = TweenLite.to(projImage, .25, {
				opacity: 1,
				ease: Power1.easeIn
			});
			let scaleDownBgImg = TweenLite.to(projImage, .5, {
				scaleX: 1,
				scaleY: 1,
				ease: Power1.easeIn
			})
			let fadeInfoOut = TweenLite.to(projInfo, .25, {
				opacity: 0
			});
			projImage.style.cursor = "auto";
		}
	}

	private growBgImage(img) {

	}
	screenIsMobile() {
		if (window.innerWidth <= 480) {
			return true;
		}
		return false;
	}

	// _fadeImage(img, dir, val){
	// 	//let image = img;
	// 	if(dir==="out"){
	// 		img.style.opacity = 0;
	// 		img.style.webkitTransition  = 'opacity 1s easeOut';// Chrome
	// 		img.style.transition  = 'opacity 1s easeOut';// Mozilla
	// 	}else{
	// 		img.style.opacity = 1;
	// 		img.style.webkitTransition  = 'opacity 3s easeOut';// Chrome
	// 		img.style.transition  = 'opacity 3s easeOut';// Mozilla
	// 	}
	// }
}