import { Component, OnInit, AfterViewInit, HostListener, HostBinding } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { trigger, stagger, keyframes, state, style, transition, animate, query } from '@angular/animations';
import { Location } from '@angular/common';
import { ProjectService } from "../../../services/projects.service";
import { fadeInAnimation } from "../../../transitions/project-detail.animations"




import { Project } from '../project.model';

@Component({
	selector: 'project-detail',
	templateUrl: './project-detail.component.html',
	styleUrls: ['./project-detail.component.css'],
	animations: [
		
			trigger('imagesIn', [
			  state('in', style({transform: 'translateY(0)'})),
			  transition('void => *', [
				animate(600, keyframes([
				  style({opacity: 0, transform: 'translateY(100%)', offset: 0}),
				  style({opacity: 1, transform: 'translateY(-15px)',  offset: 0.4}),
				  style({opacity: 1, transform: 'translateY(0)',     offset: .6})
				]))
			  ])
			])
		  ]
	  
})

export class ProjectDetailComponent implements OnInit, AfterViewInit {
	//@HostBinding('@fadeInAnimation') fadeInAnimation;

	public id: string;
	public project: Project;
	public proj;
	public url: string = "../../assets/proj-images/";
	public images = null;

	constructor(
		private route: Router,
		private actRoute: ActivatedRoute,
		private location: Location,
		private projectService: ProjectService
	) {

	}
	ngAfterViewInit() {
		console.log("After View INIT");
	}
	ngOnInit() {
		console.log("NG INIT");
		this.actRoute
			.queryParams
			.subscribe(params => {
				this.id = params['id'];
				//console.log("Loading project" + params);
				//new ProjectModel(this.project['id'],this.project['name'],this.proj['role'],this.proj['type'],this.proj['platform'],this.proj['tech'],this.proj['overview'],this.proj['status'],this.proj['images'],this.proj['icon']);

				this.getProject();
			})
	}
	getProject() {
		this.projectService.getProjectAtID(this.id)
			.subscribe(data => {
				this.project = data["projects"].find(proj => proj.id === this.id);

				console.log(this.project);
				this.images = this.project["images"];
				//this.loadImage(this.project["icon"]);

				for (let i = 0; i < this.images.length; i++) {
					//	this.loadImage(this.url+this.images[i]);
				}
				// console.log(typeof this.project);
				// console.log(this.project)
			})
	}

	backToProjects() {
		console.log(this.location);
		//	this.location.back();
		this.route.navigate(['projects/']);
	}

	loadImage(src) {
		let img = new Image();
		img.src = src;
		img.addEventListener('load', imgEle => {
			console.log(imgEle.target['src'].images);
			let newSrc = imgEle.target['src'].images;
			//	img.animate()
		})
		img.addEventListener('error', err => {
			//	return err;
		})
	}
}