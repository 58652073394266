import { Injectable } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

declare let gtag: Function;

@Injectable()
export class NavService {

    public lastValue: any;
    public newValue: any;
    public animationValue: any;

    constructor(
        private router: Router,
    ) {
        this.routerEvents();
        
    }

    routerEvents() {
        this.router
      .events
      .subscribe((event) => {
        if (event instanceof ActivationEnd) {
          const snapshot = event.snapshot;
          console.log("State "+snapshot.data.state)
          if (snapshot.data.num) {
            this.lastValue = this.newValue;
            this.newValue = snapshot.data.num;
            this.animationValue = this.getAnimationNumber(this.lastValue, this.newValue);
          }
        }
        this.trackRouterEvent(event);
      });
    }
    getAnimationNumber(lastValue: number, newValue: number) {
        let result = 0;
        if (lastValue && newValue) {
          result = 2;
          if (lastValue) {
            if (newValue > lastValue) {
              result = 2;
            } else {
              result = 1;
            }
          } else {
            result = 1;
          }
        }
        return result;
      }
      trackRouterEvent(evt) {
        console.log("EVT");
        console.log(evt);
        if (evt instanceof NavigationEnd) {
            gtag('config', 'UA-119133518-1', {'page_path': evt.url});
        }
      }
}