
import { trigger, animate, style, group, animateChild, query, stagger, transition } from '@angular/animations';

export const routerTransition = trigger('routerTransition', [
  // navigated from outside - do nothing
  transition('* => 0', [
    // Initial state of new route
    query(':enter',
      style({
        position: 'fixed',
        width: '100%',
        transform: 'translateX(100%)'
      }), { optional: true }),
  ]),
  // LEFT TO RIGHT AKA PREVIOUS
  transition('* => 1', [
    // Initial state of new route
    query(':enter',
      style({
        position: 'fixed',
        width: '100%',
        transform: 'translateX(-100%)'
      }), { optional: true }),
    // move page off screen right on leave
    query(':leave',
      animate('250ms ease-in',
        style({
          position: 'fixed',
          width: '100%',
          transform: 'translateX(100%)',
        })
      ), { optional: true }),
    // move page in screen from left to right
    query(':enter',
      animate('250ms ease-out',
        style({
          opacity: 1,
          transform: 'translateX(0%)'
        })
      ), { optional: true }),
  ]),
  // RIGHT TO LEFT AKA NEXT
  transition('* => 2', [
    // Initial state of new route
    query(':enter',
      style({
        position: 'fixed',
        width: '100%',
        transform: 'translateX(100%)'
      }), { optional: true }),
    // move page off screen right on leave
    query(':leave',
      animate('250ms ease-in',
        style({
          position: 'fixed',
          width: '100%',
          transform: 'translateX(-100%)',
        })
      ), { optional: true }),
    // move page in screen from left to right
    query(':enter',
      animate('250ms ease-out',
        style({
          opacity: 1,
          transform: 'translateX(0%)'
        })
      ), { optional: true }),
  ]),
  transition('* => 4', [
    query(':enter',
    animate('3000ms ease-out',
      style({
        opacity: '.2',
      })
    ), { optional: true }),
    query(':leave',
    animate('3000ms ease-out',
      style({
        opacity: '.2',
      })
    ), { optional: true }),
    query(':enter',
    animate('3000ms ease-out',
      style({
        opacity: '.2',
      })
    ), { optional: true })
  ])
])
// export const childTransition = trigger('childTransition', [
//   transition('* => 1.2', [
//     //   // Initial state of new route
//     query(':enter',
//       style({
//         position: 'fixed',
//         width: '10%'
//       }), { optional: true }),
//   ])
// ])
