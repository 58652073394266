import {
	Component, OnInit, Injectable,
	Injector,
	ComponentFactoryResolver,
	EmbeddedViewRef,
	ApplicationRef,
	AfterViewInit, ViewContainerRef, ViewChild, ElementRef, ComponentRef, Renderer
} from '@angular/core';

//import { trigger, state, style, transition, animate } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AppComponent } from '../../app.component';
import { MobileMenuComponent } from '../mobile-menu/mobile-menu.component';


@Component({
	//selector: 'app-root',
	// template: `<div class="menu" [@animateState]="state"></div>`,
	// styles: ['.menu {width: 200px; height: 200px; border: 1px solid black}'],
	selector: 'menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.css'],
	animations: [
		trigger('toggleMenu', [
			state('in', style({
				//transform: 'translate3d(-200px, 0, 0)'
			//	display: mobileNavStyles,
				 position: 'relative',
				right: '0',
				//top: '50px',
				// height: '75%'
			})),
			state('out', style({
				//transform: 'translate3d(100px, 0px, 0)'
			//	display:mobileNavStyles,
				 position: 'relative',
				right: '-140px',
				// height: '0px'
			})),
			transition('in => out', animate('0.2s ease-in')),
			transition('out => in', animate('0.2s ease-out'))
		]),
	]
})

export class MenuComponent implements OnInit, AfterViewInit {
	//private mobileMenu: MobileMenuComponent
	public state: string = 'out';
	public componentFactory;
	public componentRef;
	public mobileNavStyles = {'display':'none'};
	public closeMenu = null;
	private closeBtn = null;
	private appComp = null;

	@ViewChild('mobileMenuView', { read: ViewContainerRef }) mobileMenuView: ViewContainerRef;
	//@ViewChild('mobileMenuView') mobileMenuView: ElementRef;
	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private viewContainerRef: ViewContainerRef,
		private renderer: Renderer
	) { }

	ngAfterViewInit() {
		//this.mobileMenuView.clear();		
		//this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(MobileMenuComponent);
	}

	ngOnInit() {

	}


	public openMobileMenu() {
		//	this.mobileMenuView.clear();
		//	console.log(this.mobileMenuView);
		//let newMoMenu = <MobileMenuComponent>this.mobileMenuView.createComponent(this.componentFactory).instance;
		//console.log(newMoMenu)
		//	console.log(componentRef.instance.menuclose.nativeElement);
		// this.componentRef = this.mobileMenuView.createComponent(this.componentFactory);
		// let insert = this.mobileMenuView.insert(this.componentRef)
		this.mobileMenuView.clear();
		// this.componentRef = this.mobileMenuView.createComponent(this.componentFactory);
		// const inst = (<MobileMenuComponent>this.componentRef.instance);
		this.state = 'in';
		//this.closeBtn = this.mobileMenuView.element.nativeElement.menuclose;
	//	inst.display = {'display':'block'};
		//console.log(this.closeBtn);

	//	this.mobileNavStyles = {'display':'none'};

		//this.addCloseListener();
	}
	private addCloseListener() {
		console.log(this.closeBtn)
		if(this.closeBtn) {
			this.closeBtn = this.renderer.listen(this.closeBtn, 'click', (e) => {
				console.log("closing mo menu " + e);
				this.closeMobileMenu(null);
			})
		}
	}
	public closeMobileMenu(evt?) {
		console.log("Closing MM "+evt+" ");
		console.log(this.mobileMenuView);
		if(this.mobileMenuView) {
			this.state = 'out';
			//this.mobileNavStyles = {'display':'none'};
			//this.mobileMenuView.clear();
		}
	}
	/*
		Animation Event callbacks
	*/
	public animationStarted(evt) {
		console.log("Animation Started ");
		console.log(evt.fromState);
		if(evt.fromState === "out"){
			this.mobileNavStyles = {'display':'block'};
			AppComponent.addMobileMenu(true);
			//AppComponent.mobileMenuDarkenStyles = true;
		//AppComponent.updateMoMenu(true);
		//	console.log("app-comp "+this.appComp.darkenBg );
		}
	}
	public animationFinished(evt) {
		console.log("Animation Finished ");
		console.log(evt.fromState);
		if(evt.fromState === "in"){
			this.mobileNavStyles = {'display':'none'};
			AppComponent.addMobileMenu(false);
		//	AppComponent.mobileMenuDarkenStyles = false;
			//AppComponent.updateMoMenu(true);
		//	console.log("app-comp "+this.appComp.darkenBg );
		}
	}
}