import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabase } from 'angularfire2/database';
import { Http, Headers } from '@angular/http';
//import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorage } from 'angularfire2/storage';
import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';


// import * as firebase from 'firebase';
// // Required for side-effects
import firebase from '@firebase/app';
import '@firebase/functions'

//const SMTPConnection = require('nodemailer/lib/smtp-connection');


@Component({
  selector: 'contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  public cForm: FormGroup;
  public formData;
  
  public fireAuth = environment.firebase;
  @ViewChild('contactform') contactform: ElementRef;
  public showForm: boolean = true;
  public titleAlert: string = 'This field is required';
  public emailOptions = {
    port: '465',
    host: 'cloud99.hostgator.com',
    secure: 'true',
    auth: {
      user: 'Matt@SigleMaltLLC.com',
      pass: 'mShan1225'
    },
    tls: {
      // do not fail on invalid certs
      rejectUnauthorized: false
    },
    debug: 'true'
  }
  constructor(
    private fb: FormBuilder,
    private af: AngularFireModule,
    private db: AngularFireDatabase,
    //private cs: AngularFirestoreModule,
    private afStorage: AngularFireStorage,
  ) {
    this.createForm();
  }

  ngOnInit() {

  }
  createForm() {
    this.cForm = this.fb.group({
      'name': [null, Validators.required],
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      'phone': [null, Validators.nullValidator],
      'subject': [null, Validators.required],
      'message': [null, Validators.compose([Validators.required, Validators.minLength(30), Validators.maxLength(500)])],
    });
  }

  // public connectSMPT() {
  //   let connection = new SMTPConnection(this.emailOptions);
  //   connection.connect((evt) => {
  //     console.log("Connection made " + evt);
  //     connection.login(this.emailOptions.auth, (res) => {
  //       console.log("Login Successful");
  //     })
  //   })
  // }
  public submitContactForm(post): void {

    // If everything is filled out
    const date = new Date();
    const name = post.name;
    const email = post.email;
    const phone = post.phone;
    const subject = post.subject;
    const message = post.message;
    const html = `
      <div>From: ${name}</div>
      <div>Email: <a href="mailto:${email}">${email}</a></div>
      <div>Date: ${date}</div>
      <div>Phone: ${phone}</div>
      <div>Date: ${date}</div>
      <div>Message: ${message}</div>
    `;

    // let formRequest = {'name':this.name, 'email':this.email, 'phone':this.phone, 'subject':this.subject, 'message':this.message, 'date':date };
    let formRequest = { name, email, phone, subject, message, date, html };

   // this.formData = this.db.list('/messages').push(formRequest);
//    const headers = new Headers({'Authorization': 'Bearer ' + "https://accounts.google.com/o/oauth2/token" });
//     // Saving the new message to the Realtime Database.
// const sendContactMessage = (firebase as any).functions().httpsCallable('sendContactMessage');
// //let test = Object(this.af).functions().httpsCallable('sendContactMessage');
//      sendContactMessage(formRequest).then(function(result) {
//     //   // Read result of the Cloud Function.
//        var sanitizedMessage = result.data;
//        console.log(sanitizedMessage);
//     //   // ...
//      });


    this.hideContactForm();
  }
  // sendContactMessage = functions.database.ref('/messages/' + this.formData).onCreate((snapshot, context) => {
  //   const data = snapshot.val();
  //   console.log("DATA");
  //   console.log(data);
  //   // const mailOptions = {
  //   //     to: 'Matt@SingleMaltLLC.com',
  //   //     subject: `Information Request from ${data.name}`,
  //   //     html: data.html
  //   // };
  //   // return mailTransport.sendMail(mailOptions)
  //   //     .then((message) => {
  //   //         if (message.rejected.length > 0) {
  //   //             return console.log(message.rejected);
  //   //         }
  //   //         console.log("MAIL IS SENT")
  //   //         return console.log('Mail sent: ' + mailOptions.to + " val " + message.response);
  //   //     })
  // })

  public hideContactForm() {
    //  console.log(this.contactform.nativeElement.input)
    let time = setInterval((evt) => {
      this.showForm = false;
      this.cForm.reset();
      // evt.target.clearInterval();
    }, 5000)
  }

}
