// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: "http://localhost:4200",
   // Initialize Firebase
   firebase: {
    apiKey: "AIzaSyA7t1I0qeq33fACyNKNdlOO_6OwAK8MtkY",
    authDomain: "my-portfolio-2018.firebaseapp.com",
    databaseURL: "https://my-portfolio-2018.firebaseio.com",
    projectId: "my-portfolio-2018",
    storageBucket: "my-portfolio-2018.appspot.com",
    messagingSenderId: "1095321949106"
  }
  
};
