import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbPopover } from '@ng-bootstrap/ng-bootstrap';

declare let gtag: Function;

@Component({
  selector: 'social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.css']
})
export class SocialComponent implements OnInit {
  @Input() name: string;// Name of parent
  public usePopovers: boolean;
  constructor() {
  }

  ngOnInit() {
    console.log(" parent " + this.name);
    this.usePopovers = this.name === 'contact';
  }

  showPopover(evt) {
    evt.target.popover('toggle')
  }
  openLg(content) {
    //  this.modalService.open(content, { size: 'lg' });
  }
  openSocial(href: string, target?: string) {
    let _target = target ? target : '_blank';
    // console.log("Opening something "+href);
    gtag('event', "linkOut", {
      'send_to': 'UA-119133518-1',
      'event_category': 'social',
      'event_label': href
    });
    window.open(href, _target);
  }
  overSocial() {
    document.body.style.cursor = "pointer";
  }
  outSocial() {
    document.body.style.cursor = "default";
  }
}
