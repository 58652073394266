import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Project } from "../components/projects/project.model";
import { Observable } from "rxjs";
// import 'rxjs/add/observable/of';


// import { BehaviorSubject } from 'rxjs/BehaviorSubject';

// export interface Config {
//     id: string,
//     name: string;
//     type: string;
// }

@Injectable()
export class ProjectService {
    public project: Project;
    public error: Error;
    // public pr = new Array(
    //     { id: 1, name: "HomeByn", type: "Native App", role: "Developer", platform: "Android and iOS", tech: "Angular, Javascript, HTML", overview: "blah blah blah", status: "Not Sure", images: ["Edwards/IMG_0089.PNG", "Edwards/IMG_0091.PNG","Edwards/IMG_0090.PNG"], icon:"/assets/icons/homebyn-logo@2x.png" },
    //     { id: 2, name: "Edwards Life Sciences", type: "Native App", role: "Developer", platform: "Android and iOS", tech: "Angular, Javascript, HTML", overview: "blah blah blah", status: "Not Sure", images: ["civic-2015/civic_design_01.jpg","Edwards/IMG_0087.PNG","Edwards/IMG_0091.PNG","civic-2015/civic_design_01.jpg"], icon:"/assets/icons/EdwardsLogo.png"},
    //     { id: 3, name: "Edwards Native App", type: "Native App", role: "Developer", platform: "Android and iOS", tech: "Angular, Javascript, HTML", overview: "blah blah blah", status: "Not Sure", images: ["Edwards/IMG_0087.PNG", "Edwards/IMG_0091.PNG","civic-2015/civic_design_01.jpg"], icon:"/assets/icons/homebyn-logo@2x.png" },
    //     { id: 4, name: "Honda CRV", type: "Native App", role: "Developer", platform: "Android and iOS", tech: "Angular, Javascript, HTML", overview: "blah blah blah", status: "Not Sure", images: ["Edwards/IMG_0087.PNG", "Edwards/IMG_0091.PNG","Edwards/IMG_0092.PNG"], icon:"/assets/icons/homebyn-logo@2x.png" },
    //     { id: 5, name: "Acura Selling", type: "Native App", role: "Developer", platform: "Android and iOS", tech: "Angular, Javascript, HTML", overview: "blah blah blah", status: "Not Sure", images: ["Edwards/IMG_0089.PNG"], icon:"/assets/icons/homebyn-logo@2x.png" },
    //     { id: 6, name: "Ter Stegen", type: "Native App", role: "Developer", platform: "Android and iOS", tech: "Angular, Javascript, HTML", overview: "blah blah blah", status: "Not Sure", images: ["Edwards/IMG_0089.PNG", "Edwards/IMG_0091.PNG","Edwards/IMG_0090.PNG"], icon:"/assets/icons/homebyn-logo@2x.png" },
    //     { id: 7, name: "Ter Stegen", type: "Native App", role: "Developer", platform: "Android and iOS", tech: "Angular, Javascript, HTML", overview: "blah blah blah", status: "Not Sure", images: ["civic-2015/civic_design_01.jpg", "civic-2015/civic_design_02.jpg"], icon:"/assets/icons/homebyn-logo@2x.png"}
    // );
    //public projects = new Array();
    private headers = new Headers({ 'Content-Type': 'application/json' });

    constructor(private http: HttpClient) { };


    public getAllProjects(): Observable<Project> {
        return this.http.get<Project>("assets/projects.json")
        // .subscribe(
        //     data => this.config = data , // success path
        //     error => this.error = error // error path
        // )
        //console.log(returnProj);
        // returnProj.forEach(element => {
        //     console.log(element)
        //     this.projects.push(element);
        // })
        //  console.log(this.projects)
        // return this.http.get("assets/projects.json");
        // return this.projects.map(res => res.json())
    }

    //  public getProject(id: number | string): Project {
    // return this.pr.filter(project => project.id === id)[0];
    //    return this.getProjects().map(proj => proj.find(proj =>proj.id === +id));
    //   }
    public getProjectAtID(id: string): Observable<Project> {
      return this.getAllProjects();
    }

    private getLocationString(): String {
        let location: string = document.location.toString();
        //  location.substring(0,location.lastIndexOf('/'));
        console.log("WTF " + location.lastIndexOf('/'));
        return location.substring(0, location.lastIndexOf('/'));
    }
}  